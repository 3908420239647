export default function useDocumentSearch(document: string, { limit = 20 } = {}) {
  const app = useNuxtApp()
  const endpoint = computed(() => `${unref(document)}s`)
  const loading = ref(false)
  const offset = ref(0)
  const results = ref([])
  const canLoadMore = ref(true)
  const internalSearch = async (query?: string, params?: any) => {
    const cleanQuery = query?.trim().toLowerCase()

    if (cleanQuery) {
      loading.value = true
      try {
        const newResults = await app.$api.v1.search[endpoint.value as keyof typeof app.$api.v1.search]({
          pagination: { limit, offset: unref(offset) },
          search: cleanQuery,
          ...params,
        })

        canLoadMore.value = newResults.length === limit
        offset.value += limit
        results.value = results.value.concat(newResults)
      }
      catch (error) {
        app.$error.report(error as any)
      }
      loading.value = false
    }
  }
  const search = (query: string, params?: any) => {
    results.value = []
    offset.value = 0
    return internalSearch(query, params)
  }

  return {
    canLoadMore,
    loading,
    loadMore: internalSearch,
    results,
    search,
  }
}
